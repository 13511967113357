import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import { Grid, Box, Flex } from 'src/components/FlexBox';
import ModuleWrapper from 'src/components/ModuleWrapper';
import Text from 'src/components/Text';
import zipWith from 'lodash/zipWith';
import SmartButton from 'src/components/SmartButton';

export default ({ stats: { titles, subtitles }, buttons, ...other }) => {
  return (
    <ModuleWrapper
      {...other}
      flexDirection="column"
      css={css({
        py: 'sectionMargin',
        px: 'pageMargin',
      })}
    >
      <Grid
        gridTemplateColumns={[
          '1fr',
          null,
          `repeat(${Math.min(titles.length, subtitles.length)}, 1fr)`,
        ]}
        gx={4}
        gy={5}
        justifyItems="center"
      >
        {zipWith(titles, subtitles, (title, subtitle) => (
          <Box alignItems="center" textAlign="center" flexDirection="column">
            <Text variant="heading.xxxl">
              <b css={css({ px: '0.1ch' })}>{title}</b>
            </Text>
            <Text variant="small" css={css({ mt: 2 })}>
              {subtitle}
            </Text>
          </Box>
        ))}
      </Grid>
      <Flex
        gx={5}
        gy={4}
        justifyContent="center"
        textAlign="center"
        css={css({ mt: 5 })}
      >
        {buttons &&
          buttons.map((button) => <SmartButton key={button.id} {...button} />)}
      </Flex>
    </ModuleWrapper>
  );
};

export const query = graphql`
  fragment StatsModuleFragment on ContentfulStatsModule {
    stats {
      titles
      subtitles
    }
    background {
      ...ImageGroupFragment
    }
    buttons {
      ...ButtonFragment
    }
  }
`;
